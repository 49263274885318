import React from 'react'
import { graphql } from 'gatsby'
import Type, {TypeQueryData} from '../../templates/type'

interface Props {
    data: TypeQueryData
}
export default ({data}: Props) => {

    const HoofdTekst = () => {
        return (
            <>
            </>
        )
    }

    return (
        <Type
            data={data}
            title={'Type C'}
            soortWoningen={'3 hoger gelegen kadewoningen'}
            aantalWoningen={'Drielaags'}
            hoofdTekst={<HoofdTekst/>}
            pageDescription={'Alle woningen van Kade 23 lopen in een kleine bocht mee met de gracht. Dit betekent dat ze taps toelopen naar het eind. De voorkant van de woning is dan ook breder dan de achterkant en geeft een speels effect.'}
            kenmerken={[
                'Woonoppervlak van 130 m2',
                'Hoger gelegen, entree via klein trappetje',
                'Tuin op het westen',
                'Perceel 87 m2  - 102,5 m2',
                'Overdekte parkeerplek in achtergelegen parkeergarage'
            ]}
        />
    );
}

export const pageQuery = graphql`
    query {
        bouwtype(diversen: {objectcode: {eq: "O100098"}}) {
            ...Bouwtype
        }
        other: allBouwtype(filter: {diversen: {objectcode: {ne: "O100098"}}}) {
            nodes {
                algemeen {
                    omschrijving
                }
                fields {
                    slug
                }
            }
        }
    }
`
